<template>
  <v-container class="join justify-center" fluid fill-height>
    <v-form ref="playForm" @submit.prevent="validatePin()">
      <v-container class="join-inner background pa-sm-8 mx-auto">
        <v-row align="center" justify="center" class="pa-2">
          <v-col cols="12" md="6" lg="6">
            <lottie-player
              src="https://assets3.lottiefiles.com/packages/lf20_e16yAs.json"
              background="transparent"
              speed="1"
              autoplay
              :style="$vuetify.breakpoint.smAndDown ? 'height: 30vh' : ''"
              loop
            ></lottie-player>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <h1 class="text-h4 justify-center secondary--text mb-5 font-weight-black">Let's Play!</h1>
            <v-text-field
              v-model="gamepin.pin"
              label="Game pin"
              :rules="pinrule"
              filled
              clearable
              x-large
            ></v-text-field>
            <v-text-field
              v-model="gamepin.nickname"
              label="Nickname"
              :rules="namerule"
              filled
              clearable
              x-large
            ></v-text-field>

            <v-row align="center">
              <v-col cols="12" md="6">
                <v-btn x-large block color="secondary" type="submit">
                  <font-awesome-icon icon="play" size="lg" class="mr-4" pull="left"></font-awesome-icon>
                  Play
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn x-large block color="secondary" outlined @click="goBack">
                  <font-awesome-icon icon="arrow-left" size="lg" class="mr-4" pull="left"></font-awesome-icon>
                  Go Back</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <Loading :loading="loading" :text="participantjoiningText" />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { checkActiveSession, createParticipantSession } from "@/api/GameApi"
import { loginSignupMixin } from "@/mixins/loginSignupMixin.js"
import { mapMutations, mapActions } from "vuex"

export default {
  name: "Join",
  metaInfo: {
    title: "Let the fun begin, Join an interesting and engaging quiz",
    titleTemplate: "Qizto | %s ",
    meta: [
      {
        name: "description",
        content: "Participate in a quiz or poll, compete with your friends, family and colleagues and Have fun",
      },
    ],
  },
  mixins: [loginSignupMixin],
  components: {
    Loading: () => import(/* webpackChunkName: "loading" */ "@/components/common/Loading.vue"),
  },
  data() {
    return {
      gamepin: { pin: "", nickname: "" },
      namerule: [(v) => !!v || "Nickname is required!"],
      pinrule: [(v) => !!v || "Quizpin is required!"],
      participantjoiningText: "You're In...",
      snackmsg: null,
      light_mode_logo: require("@/assets/common/logo_lightmode.svg"),
    }
  },

  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR" }),
    ...mapActions(["bindParticipantSession", "bindGameSession", "bindGame", "bindparticipantList"]),

    randomColors() {
      const colorCode = [
        "#FAE83D",
        "#000000",
        "#FFA73C",
        "#834181",
        "#F83F37",
        "#3A800D",
        "#F58A4B",
        "#C03028",
        "#EBC844",
        "#A2B86C",
        "#9CABB6",
        "#54B948",
        "#EA5E3D",
        "#F25892",
        "#D7E035",
        "#4EB9A3",
        "#705027",
        "#4C5454",
        "#CD3A4E",
      ]
      const randomGenerate = Math.floor(Math.random() * colorCode.length)
      return colorCode[randomGenerate]
    },

    async validatePin() {
      if (this.$refs.playForm.validate()) {
        this.loading = !this.loading
        localStorage.qiztostart = "notstarted"
        const color = this.randomColors()
        try {
          const result = await checkActiveSession(this.gamepin.pin)
          if (result) {
            const routeparam = await createParticipantSession(result, this.gamepin.nickname, color)
            // console.log(routeparam)
            this.$router.push({ name: "Participant", params: { id: routeparam } })
          }
        } catch (err) {
          // console.log(err)
          // this.snackmsg = "Incorrect Pin or Game hasn't started yet!"
          this.showAlert(err)
        } finally {
          this.loading = !this.loading
        }
      }
    },

    goBack() {
      this.$router.push("/", () => {})
    },
  },
}
</script>
<style lang="scss" scoped>
.join {
  min-height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-image: url("~@/assets/background-bottom.svg"),
    linear-gradient(174deg, rgba(255, 243, 224, 1) 20%, rgba(255, 223, 173, 1) 78%);
  background-blend-mode: overlay;
  .join-inner {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
}
</style>
