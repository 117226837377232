// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/background-bottom.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".join[data-v-4bfaeb64] {\n  min-height: 90vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: bottom left;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), linear-gradient(174deg, #fff3e0 20%, #ffdfad 78%);\n  background-blend-mode: overlay;\n}\n.join .join-inner[data-v-4bfaeb64] {\n  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;\n}", ""]);
// Exports
module.exports = exports;
