var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "join justify-center",
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-form",
        {
          ref: "playForm",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validatePin()
            }
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "join-inner background pa-sm-8 mx-auto" },
            [
              _c(
                "v-row",
                {
                  staticClass: "pa-2",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "6" } },
                    [
                      _c("lottie-player", {
                        style: _vm.$vuetify.breakpoint.smAndDown
                          ? "height: 30vh"
                          : "",
                        attrs: {
                          src:
                            "https://assets3.lottiefiles.com/packages/lf20_e16yAs.json",
                          background: "transparent",
                          speed: "1",
                          autoplay: "",
                          loop: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "6", md: "6" } },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "text-h4 justify-center secondary--text mb-5 font-weight-black"
                        },
                        [_vm._v("Let's Play!")]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: "Game pin",
                          rules: _vm.pinrule,
                          filled: "",
                          clearable: "",
                          "x-large": ""
                        },
                        model: {
                          value: _vm.gamepin.pin,
                          callback: function($$v) {
                            _vm.$set(_vm.gamepin, "pin", $$v)
                          },
                          expression: "gamepin.pin"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Nickname",
                          rules: _vm.namerule,
                          filled: "",
                          clearable: "",
                          "x-large": ""
                        },
                        model: {
                          value: _vm.gamepin.nickname,
                          callback: function($$v) {
                            _vm.$set(_vm.gamepin, "nickname", $$v)
                          },
                          expression: "gamepin.nickname"
                        }
                      }),
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "secondary",
                                    type: "submit"
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "mr-4",
                                    attrs: {
                                      icon: "play",
                                      size: "lg",
                                      pull: "left"
                                    }
                                  }),
                                  _vm._v(" Play ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "secondary",
                                    outlined: ""
                                  },
                                  on: { click: _vm.goBack }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "mr-4",
                                    attrs: {
                                      icon: "arrow-left",
                                      size: "lg",
                                      pull: "left"
                                    }
                                  }),
                                  _vm._v(" Go Back")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("Loading", {
                attrs: {
                  loading: _vm.loading,
                  text: _vm.participantjoiningText
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }